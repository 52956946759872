






import { Component, Vue } from 'vue-property-decorator';
import {ACTION_TOKEN_LOGIN} from '@/store/constants';
@Component({
    components: {
    },
    async mounted() {
        if (!!this.$store.state.app.loginToken) {
            await this.$store.dispatch(ACTION_TOKEN_LOGIN);
            if(!this.$store.state.app.isLogin){
                this.$router.push('/login');
            }
        }
    },
})
export default class App extends Vue {
}
