import { render, staticRenderFns } from "./forgetPassword.vue?vue&type=template&id=2b2e0fca&scoped=true&"
import script from "./forgetPassword.vue?vue&type=script&lang=js&"
export * from "./forgetPassword.vue?vue&type=script&lang=js&"
import style0 from "./forgetPassword.vue?vue&type=style&index=0&id=2b2e0fca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2e0fca",
  null
  
)

export default component.exports